<!-- TrailDataCardWeather.vue -->
<template>
  <transition name="weather-toast">
    <div class="weather-toast px-3 py-2" v-if="showWeather">
      <span class="hide-weather material-icons" @click="hideWeather">
        remove
      </span>
      <div class="weather-left d-flex flex-row">
        <span class="mr-2" title="Weather Condition">
          {{ getWeatherIcon }}
        </span>
        <div>
          <p class="m-0">
            <strong>Weather for {{ shortAddress || 'Your Trail' }}</strong>
          </p>
          <small>{{ todayDate }}</small>
        </div>
      </div>

      <div class="weather-right ml-md-auto mt-2 mt-md-0 d-flex flex-wrap">
        <div class="weather-chip rounded mr-2 mb-2">
          <span class="chip-label">Min</span>
          <span class="chip-value">{{ weather.daily.temperature_2m_min[0] }}°C</span>
        </div>
        <div class="weather-chip rounded mr-2 mb-2">
          <span class="chip-label">Max</span>
          <span class="chip-value">{{ weather.daily.temperature_2m_max[0] }}°C</span>
        </div>
        <div class="weather-chip rounded mr-2 mb-2">
          <span class="chip-label">Sunrise</span>
          <span class="chip-value">{{ formatTime(weather.daily.sunrise[0]) }}</span>
        </div>
        <div class="weather-chip rounded mr-2 mb-2">
          <span class="chip-label">Sunset</span>
          <span class="chip-value">{{ formatTime(weather.daily.sunset[0]) }}</span>
        </div>
        <div class="weather-chip rounded mr-2 mb-2">
          <span class="chip-label">Precip</span>
          <span class="chip-value">{{ weather.daily.precipitation_sum[0] }} mm</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import getWeatherIcon from "../utils/getWeatherIcon";

export default defineComponent({
  data() {
    return {
      todayDate: new Date().toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      })
    }
  },
  name: 'TrailDataCardWeather',
  props: {
    showWeather: {
      type: Boolean,
      required: true,
    },
    weather: {
      type: Object,
      required: true,
    },
    shortAddress: {
      type: String,
      default: '',
    },
  },
  methods: {
    hideWeather() {
      // Emit an event so the parent component can hide the weather toast.
      this.$emit('hide-weather');
    },
    formatTime(isoTime: string): string {
      if (!isoTime) return '';
      const date = new Date(isoTime);
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
  },
  computed: {
    getWeatherIcon(): string {
      // You can return the weather icon based on the weather condition.
      return getWeatherIcon(this.weather);
    },
  }
});
</script>

<style scoped>
/* These styles are extracted from your original style block */
.weather-toast {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 105%;
  width: 50%;
  background: linear-gradient(
    135deg,
    rgba(42, 126, 211, 0.8) 0%,
    rgba(65, 66, 73, 0.9) 100%
  );
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 822px) {
    width: 100%;
  }
}

.reduce .weather-toast {
  width: 100%;
}

.weather-toast-enter-active,
.weather-toast-leave-active {
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.weather-toast-enter {
  transform: translateY(100%);
  opacity: 0;
}
.weather-toast-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.weather-left {
  flex: 0 0 auto;
}

.weather-right {
  flex: 1 1 auto;
}

.weather-icon {
  font-size: 32px;
}

.weather-chip {
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 8px;
  min-width: 70px;
  text-align: center;
  color: #fff;
}

.weather-chip .chip-label {
  display: block;
  font-size: 0.7em;
  opacity: 0.7;
}

.weather-chip .chip-value {
  font-weight: bold;
}

.hide-weather {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
</style>
