<template>
  <div class="modal fade" style="background-color: rgba(7,7,7,0.36);" id="map-styles-dialog" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">

          <div class="container">
            <div class="row">

              <!-- List free map styles -->
              <div class="col-12 py-3">
                <h3 class="display-5"><strong>Standard maps</strong></h3>
                <div class="position-relative d-inline-block"
                     v-for="(style, index) in freeMapStyles"
                     :key="index"
                     @click="onStyleClick(style)">

                  <div class="mapstyle-card" :class="{'current-style': currentMapStyle && style.name === currentMapStyle.name}">
                    <img :src="style.image"  alt="Map">

                    <div class="hiking-text p-1">
                      <small><p class="m-0"><strong>{{ style.name }}</strong></p></small>
                      <small><p class="text-muted">{{ style.isPro ? 'PRO+' : 'Free' }}</p></small>
                    </div>

                  </div>
                </div>
              </div>

              <!-- List PRO map styles -->
              <div class="col-12 py-3 card d-block">
                <h3 class="display-5"><strong>PRO+ map collections</strong></h3>
                <div class="position-relative d-inline-block"
                     v-for="(style, index) in proMapStyles"
                     :key="index"
                     @click="onStyleClick(style)">

                  <template v-if="printMapMode && !style.isPrintMap">
                    <div class="mapstyle-card cannot-print" :class="{'current-style': currentMapStyle && style.name === currentMapStyle.name}">
                      <img :src="style.image"  alt="Map">

                      <div class="hiking-text p-1">
                        <small><p class="m-0"><strong>{{ style.name }}</strong></p></small>
                        <small><p class="text-muted">{{ style.isPro ? 'PRO+' : 'Free' }}</p></small>
                      </div>

                      <a href="https://blog.hiiker.app/2024/03/09/introducing-map-collections/" target="_blank">
                        <span class="material-icons map-info">info</span>
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mapstyle-card" :class="{'current-style': currentMapStyle && style.name === currentMapStyle.name}">
                      <img :src="style.image"  alt="Map">

                      <div class="hiking-text p-1">
                        <small><p class="m-0"><strong>{{ style.name }}</strong></p></small>
                        <small><p class="text-muted">{{ style.isPro ? 'PRO+' : 'Free' }}</p></small>
                      </div>

                      <a href="https://blog.hiiker.app/2024/03/09/introducing-map-collections/" target="_blank">
                        <span class="material-icons map-info">info</span>
                      </a>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button id="close_button_map_styles" class="btn btn-secondary mt-3" data-bs-dismiss="modal">CLOSE</button>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {HiikerMapStyle} from "../utils/models/MapStyles";
import {RevenueCatWrapper} from "../utils/RevenueCatWrapper";
import axiosClient from "../utils/helpers/axios_client";

export default defineComponent({
  data() {
    return {
      mapStyles: [] as HiikerMapStyle[],
      is3DEnabled: false,
    };
  },
  async beforeCreate() {
    window.bus.$on('user:updated', async () => {
      const response = await axiosClient.get('/user/current');
      // @ts-ignore
      if (response.data) {
        this.user = response.data;
        // @ts-ignore
        window.user = this.user;
      }
    });
  },
  async mounted() {
    this.mapStyles = HiikerMapStyle.getAllStyles();
    const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();

    // Instead of setting currentStyle here, commit to the store
    const current = HiikerMapStyle.getCurrentStyle(isProOrProPlus);
    if (current) {
      this.$store.commit('mapStyles/setCurrentMapStyle', current);
    }
  },
  methods: {
    user()  {
      return (window.user && window.user.firebase_user_id) ? window.user : null;
    },
    async onStyleClick(style: HiikerMapStyle) {
      const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();

      if (this.printMapMode && !style.isPrintMap) {
        alert('This collection is not available for printing yet...');
        return;
      }

      if (!style.isPro || (window.user && isProOrProPlus)) {
        // Use the store mutation
        this.$store.commit('mapStyles/setCurrentMapStyle', style);

        // Save style as current through your model if needed
        style.saveCurrentStyle();

        window.map.once('style.load', () => {
          window.bus.$emit('map-style-loaded');
        });

        if (this.$store.state.mapStyles.printMapMode) {
          window.map.setStyle(style.printMapUrl());
        } else {
          window.map.setStyle(style.styleUrl);
        }
        setTimeout(() => {
          window.bus.$emit('map-style-loaded');
        }, 2500);
        this.is3DEnabled = false;

        const closeButton = document.getElementById('close_button_map_styles');
        if (closeButton) {
          closeButton.click();
        }
      } else {
        window.location.href = "/purchases"
      }
    }
  },
  computed: {
    currentMapStyle() {
      return this.$store.state.mapStyles.currentMapStyle;
    },
    freeMapStyles() {
      return this.mapStyles.filter(style => !style.isPro);
    },
    proMapStyles() {
      return this.mapStyles.filter(style => style.isPro);
    },
    printMapMode() {
      return this.$store.state.mapStyles.printMapMode;
    }
  },
  watch: {
    is3DEnabled(newVal) {
      const map = window.map;
      if (newVal) {
        if (map.getSource('mapbox-dem') == null) {
          map.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            'tileSize': 512,
            'maxzoom': 14
          });
        }
        if (map.getTerrain() == null) {
          map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        }
        map.setPitch(80);
      } else {
        if (map.getTerrain()) {
          map.setTerrain(null);
        }
        map.setPitch(0);
      }
    }
  },
});
</script>

<style scoped>
.modal {
  background-color: rgba(7, 7, 7, 0.7) !important;
}
.modal-dialog{
  max-height: 80vh;
}
li {
  cursor: pointer;
}

.mapstyle-card{
  position: relative;
  width: 120px;
  background-color: #fff;
  border: 2px solid #434343;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 1em;
  margin-right: 1em;
}

.mapstyle-card img {
  width: 100%;
  height: auto;
}

.mapstyle-card .hiking-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
}

.mapstyle-card .map-info{
  position: absolute;
  z-index: 100;
  top: 6px;
  left: 6px;
  background: #17181D;
  border-radius: 100px;
}

.mapStyleThumbnail{
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.current-style {
  border: 2px solid #fddf00;
}

.cannot-print {
  opacity: 0.5;
}

@media (max-width: 475px) {
  .mapstyle-card{
    width: 94px;
    margin-right: .5em;
  }

  .map-info{
    font-size: 19px;
  }
}
</style>
