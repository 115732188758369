import {gql} from "graphql-request";

export const SearchUsersRecordedTrails = gql`
  query SearchUsersRecordedTrails(
    $string: String!
    $page: Int
    $pageSize: Int
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    recordingsTextSearch(
      string: $string
      page: $page
      pageSize: $pageSize
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      title
      distanceMeters
      thumbnailUrl
      obfuscatedSlug
      createdAt
      shareableUrl
      elapsedSeconds
      elevationGainMeters
      lineString
      images {
        file {
          url
        }
      }
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
        slug
        regionSlug
        countrySlug
      }

    }
  }
`;

export const GetRecordedTrailById = gql`
  query GetRecordedTrailById($slug: String!) {
    recordingBySlug(slug: $slug) {
      id
      clientId
      updatedAt
      public
      createdAt
      lineString
      body
      title
      deleted
      obfuscatedSlug
      images {
        id
        file {
          url
        }
      }
      startAddress {
        region
        regionCountry
        city
        cityDistrict
        country
        name
      }
      elapsedSeconds
      distanceMeters
      elevationGainMeters
      errors {
        fieldName
        errors
      }
      timeStamps
      verticalAccuracy
      horizontalAccuracy
      course
      courseAccuracy
      speed
      thumbnailUrl
      speedAccuracy
      shareableUrl
      altitudes
      distances
      recording {
        id
      }
      plannedTrail {
        id
      }
      review {
        id
        userId
        trailId
        body
        rating
        createdAt
        updatedAt
        photos {
          id
          lgUrl
          metadata
        }
        firebaseReviewId
        likes {
          id
          likeableType
          likeableId
          user {
            firebaseUserId
          }
        }
        user {
          firebaseUserId
          avatar {
            id
            url
            metadata
          }
          title
          id
        }
      }
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
      }
      publicUser {
        displayName
        firebaseUserId
      }
    }
  }
`;

export const GetRecordedTrailByRecordingId = gql`
  query GetRecordedTrailById($id: ID!) {
    recording(id: $id) {
      id
      clientId
      updatedAt
      public
      createdAt
      lineString
      body
      title
      deleted
      obfuscatedSlug
      images {
        id
        file {
          url
        }
      }
      startAddress {
        region
        regionCountry
        city
        cityDistrict
        country
        name
      }
      elapsedSeconds
      distanceMeters
      elevationGainMeters
      errors {
        fieldName
        errors
      }
      timeStamps
      verticalAccuracy
      horizontalAccuracy
      course
      courseAccuracy
      speed
      thumbnailUrl
      speedAccuracy
      shareableUrl
      altitudes
      distances
      recording {
        id
      }
      plannedTrail {
        id
      }
      review {
        id
        userId
        trailId
        body
        rating
        createdAt
        updatedAt
        photos {
          id
          lgUrl
          metadata
        }
        firebaseReviewId
        likes {
          id
          likeableType
          likeableId
          user {
            firebaseUserId
          }
        }
        user {
          firebaseUserId
          avatar {
            id
            url
            metadata
          }
          title
          id
        }
      }
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
      }
      publicUser {
        displayName
        firebaseUserId
      }
    }
  }
`;

export const GetRecordedTrailLineStringById = gql`
  query GetRecordedTrailById($input: ID!) {
    recording(id: $input) {
      id
      title
      lineString
    }
  }
`;

export const GetRecordingGpxById = gql`
  query GetRecordingGpxById($id: ID!) {
    recordingGpx(id: $id)
  }
`;

export const CreateRecording = gql`
  mutation CreateRecording($input: CreateRecordingInput!) {
    createRecording(input: $input) {
      id
    }
  }
`;

export const DestroyRecording = gql`
  mutation DestroyRecording($input: DestroyRecordingInput!) {
    destroyRecording(input: $input) {
      id
      title
      createdAt
      deleted
    }
  }
`;

export const UserRecordingsQuery = gql`
  query UserRecordings($page: Int) {
    recordings(page: $page) {
      id
      title
      createdAt
      thumbnailUrl
      shareableUrl
      distanceMeters
      elapsedSeconds
      elevationGainMeters
      trail {
        id
        name
        trailDistance
        durationString
        shortAddress
        staticMapImage
        jumboImage {
          id
          url
          metadata
        }
        regionSlug
        countrySlug
        slug
      }
    }
  }
`;

export const UpdateRecording = gql`
  mutation UpdateRecording($input: UpdateRecordingInput!) {
    updateRecording(input: $input) {
      id
      title
      body
      createdAt
    }
  }
`;
