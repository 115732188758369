import {MeasurementPref} from "./MeasurementPref";

export enum HikeTime {
  twoKmPerHourAnd300MetersPerHour = 'twoKmPerHourAnd300MetersPerHour',
  threeKmPerHourAnd400MetersPerHour = 'threeKmPerHourAnd400MetersPerHour',
  fourKmPerHourAnd500MetersPerHour = 'fourKmPerHourAnd500MetersPerHour',
  fiveKmPerHourAnd600MetersPerHour = 'fiveKmPerHourAnd600MetersPerHour',
  sixKmPerHourAnd700MetersPerHour = 'sixKmPerHourAnd700MetersPerHour',
  sevenKmPerHourAnd800MetersPerHour = 'sevenKmPerHourAnd800MetersPerHour',
  eightKmPerHourAnd900MetersPerHour = 'eightKmPerHourAnd900MetersPerHour',
  nineKmPerHourAnd1000MetersPerHour = 'nineKmPerHourAnd1000MetersPerHour',
  tenKmPerHourAnd1100MetersPerHour = 'tenKmPerHourAnd1100MetersPerHour',
}

export class HikeTimeUtility {
  private static baseDistanceMultiplier = 0.012;
  private static baseElevGainMultiplier = 0.10;

  hikeTime: HikeTime;

  constructor(hikeTime: HikeTime) {
    this.hikeTime = hikeTime;
  }

  kmDistance(): number {
    switch (this.hikeTime) {
      case HikeTime.twoKmPerHourAnd300MetersPerHour:
        return 2;
      case HikeTime.threeKmPerHourAnd400MetersPerHour:
        return 3;
      case HikeTime.fourKmPerHourAnd500MetersPerHour:
        return 4;
      case HikeTime.fiveKmPerHourAnd600MetersPerHour:
        return 5;
      case HikeTime.sixKmPerHourAnd700MetersPerHour:
        return 6;
      case HikeTime.sevenKmPerHourAnd800MetersPerHour:
        return 7;
      case HikeTime.eightKmPerHourAnd900MetersPerHour:
        return 8;
      case HikeTime.nineKmPerHourAnd1000MetersPerHour:
        return 9;
      case HikeTime.tenKmPerHourAnd1100MetersPerHour:
        return 10;
    }
  }

  meterElevationGain(): number {
    switch (this.hikeTime) {
      case HikeTime.twoKmPerHourAnd300MetersPerHour:
        return 300;
      case HikeTime.threeKmPerHourAnd400MetersPerHour:
        return 400;
      case HikeTime.fourKmPerHourAnd500MetersPerHour:
        return 500;
      case HikeTime.fiveKmPerHourAnd600MetersPerHour:
        return 600;
      case HikeTime.sixKmPerHourAnd700MetersPerHour:
        return 700;
      case HikeTime.sevenKmPerHourAnd800MetersPerHour:
        return 800;
      case HikeTime.eightKmPerHourAnd900MetersPerHour:
        return 900;
      case HikeTime.nineKmPerHourAnd1000MetersPerHour:
        return 1000;
      case HikeTime.tenKmPerHourAnd1100MetersPerHour:
        return 1100;
    }
  }

  distanceMultiplier(): number {
    switch (this.hikeTime) {
      case HikeTime.twoKmPerHourAnd300MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 2.0);
      case HikeTime.threeKmPerHourAnd400MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 3.0);
      case HikeTime.fourKmPerHourAnd500MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 4.0);
      case HikeTime.fiveKmPerHourAnd600MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 5.0);
      case HikeTime.sixKmPerHourAnd700MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 6.0);
      case HikeTime.sevenKmPerHourAnd800MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 7.0);
      case HikeTime.eightKmPerHourAnd900MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 8.0);
      case HikeTime.nineKmPerHourAnd1000MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 9.0);
      case HikeTime.tenKmPerHourAnd1100MetersPerHour:
        return HikeTimeUtility.baseDistanceMultiplier * (5.0 / 10.0);
    }
  }

  elevGainMultiplier(): number {
    switch (this.hikeTime) {
      case HikeTime.twoKmPerHourAnd300MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 3.0);
      case HikeTime.threeKmPerHourAnd400MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 4.0);
      case HikeTime.fourKmPerHourAnd500MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 5.0);
      case HikeTime.fiveKmPerHourAnd600MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 6.0);
      case HikeTime.sixKmPerHourAnd700MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 7.0);
      case HikeTime.sevenKmPerHourAnd800MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 8.0);
      case HikeTime.eightKmPerHourAnd900MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 9.0);
      case HikeTime.nineKmPerHourAnd1000MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 10.0);
      case HikeTime.tenKmPerHourAnd1100MetersPerHour:
        return HikeTimeUtility.baseElevGainMultiplier * (6.0 / 11.0);
    }
  }

  multiplier(): number {
    switch (this.hikeTime) {
      case HikeTime.twoKmPerHourAnd300MetersPerHour:
        return 1.6;
      case HikeTime.threeKmPerHourAnd400MetersPerHour:
        return 1.4;
      case HikeTime.fourKmPerHourAnd500MetersPerHour:
        return 1.2;
      case HikeTime.fiveKmPerHourAnd600MetersPerHour:
        return 1.0;
      case HikeTime.sixKmPerHourAnd700MetersPerHour:
        return 0.9;
      case HikeTime.sevenKmPerHourAnd800MetersPerHour:
        return 0.8;
      case HikeTime.eightKmPerHourAnd900MetersPerHour:
        return 0.7;
      case HikeTime.nineKmPerHourAnd1000MetersPerHour:
        return 0.6;
      case HikeTime.tenKmPerHourAnd1100MetersPerHour:
        return 0.5;
    }
  }

  prettyString(): string {
    return `${this.kmDistance()} km/h and ${this.meterElevationGain()} m/h`;
  }

  static preferenceFromString(preference: string): HikeTimeUtility {
    switch (preference) {
      case '2 km/h and 300 m/h':
        return new HikeTimeUtility(HikeTime.twoKmPerHourAnd300MetersPerHour);
      case '3 km/h and 400 m/h':
        return new HikeTimeUtility(HikeTime.threeKmPerHourAnd400MetersPerHour);
      case '4 km/h and 500 m/h':
        return new HikeTimeUtility(HikeTime.fourKmPerHourAnd500MetersPerHour);
      case '5 km/h and 600 m/h':
        return new HikeTimeUtility(HikeTime.fiveKmPerHourAnd600MetersPerHour);
      case '6 km/h and 700 m/h':
        return new HikeTimeUtility(HikeTime.sixKmPerHourAnd700MetersPerHour);
      case '7 km/h and 800 m/h':
        return new HikeTimeUtility(HikeTime.sevenKmPerHourAnd800MetersPerHour);
      case '8 km/h and 900 m/h':
        return new HikeTimeUtility(HikeTime.eightKmPerHourAnd900MetersPerHour);
      case '9 km/h and 1000 m/h':
        return new HikeTimeUtility(HikeTime.nineKmPerHourAnd1000MetersPerHour);
      case '10 km/h and 1100 m/h':
        return new HikeTimeUtility(HikeTime.tenKmPerHourAnd1100MetersPerHour);
    }
    switch (preference.toLowerCase()) {
      case 'twoKmPerHourAnd300MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.twoKmPerHourAnd300MetersPerHour);
      case 'threeKmPerHourAnd400MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.threeKmPerHourAnd400MetersPerHour);
      case 'fourKmPerHourAnd500MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.fourKmPerHourAnd500MetersPerHour);
      case 'fiveKmPerHourAnd600MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.fiveKmPerHourAnd600MetersPerHour);
      case 'sixKmPerHourAnd700MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.sixKmPerHourAnd700MetersPerHour);
      case 'sevenKmPerHourAnd800MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.sevenKmPerHourAnd800MetersPerHour);
      case 'eightKmPerHourAnd900MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.eightKmPerHourAnd900MetersPerHour);
      case 'nineKmPerHourAnd1000MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.nineKmPerHourAnd1000MetersPerHour);
      case 'tenKmPerHourAnd1100MetersPerHour'.toLowerCase():
        return new HikeTimeUtility(HikeTime.tenKmPerHourAnd1100MetersPerHour);
    }
    throw new Error(`Unknown preference: ${preference}`);
  }

  secondsDurationForWalkDistance(distanceMeters: number): number {
    const mins = distanceMeters * this.distanceMultiplier()
    return mins * 60;
  }

  secondsDurationForElevationGain(elevationMeters: number): number {
    const mins = elevationMeters * this.elevGainMultiplier()
    return mins * 60;
  }

  secondsDuration(
    elevationGainMeters: number,
    distanceMeters: number
  ): number {
    return (
      this.secondsDurationForWalkDistance(distanceMeters) +
      this.secondsDurationForElevationGain(elevationGainMeters)
    );
  }


  static preference(): HikeTimeUtility {
    if (window.user) {
      // @ts-ignore
      return HikeTimeUtility.preferenceFromString(window.user.hike_time.toLowerCase())
    } else {
      return new HikeTimeUtility(HikeTime.fiveKmPerHourAnd600MetersPerHour)
    }
  }
}

export class PrettyTime {
  private hoursPerDay: number = 5.0;

  constructor(private timeInterval: number) {}

  prettyTime(): string {
    // Convert timeInterval to custom components
    const totalSeconds = Math.floor(this.timeInterval);
    const months = Math.floor(totalSeconds / (this.hoursPerDay * 3600 * 30));
    const days = Math.floor((totalSeconds % (this.hoursPerDay * 3600 * 30)) / (this.hoursPerDay * 3600));
    const hours = Math.floor((totalSeconds % (this.hoursPerDay * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Create an array to hold the formatted parts
    const parts: string[] = [];

    if (months > 0) {
      parts.push(`${months} mth`);
      parts.push(`${days} day`);
    } else if (days > 0) {
      parts.push(`${days} day`);
      if (days === 1) {
        parts.push(`${hours} hrs`);
      }
    } else if (hours > 0) {
      parts.push(`${hours} hrs`);
      parts.push(`${minutes} min`);
    } else if (minutes > 0) {
      parts.push(`${minutes} min`);
    } else {
      parts.push(`${seconds} sec`);
    }

    // Join the parts with a space
    return parts.join(' ');
  }

  unadjustedPrettyTime(): string {
    // Convert timeInterval to 24-hour components
    const totalSeconds = Math.floor(this.timeInterval);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Create an array to hold the formatted parts
    const parts: string[] = [];

    if (days > 0) {
      parts.push(`${days} day`);
      parts.push(`${hours} hrs`);
    } else if (hours > 0) {
      parts.push(`${hours} hrs`);
      parts.push(`${minutes} min`);
    } else if (minutes > 0) {
      parts.push(`${minutes} min`);
    } else {
      parts.push(`${seconds} sec`);
    }

    // Join the parts with a space
    return parts.join(' ');
  }
}
