<template>
  <div class="position-absolute button-container">
    <div class="trail-map-buttons">
      <div v-for="button in buttons" :key="button.id">
        <template v-if="button.enabled">
          <template v-if="button.clear">
            <button class="btn btn-sm btn-clear rounded-pill d-flex align-items-center ml-2" @click="buttonClicked(button.id)">
              {{ button.name }}
            </button>
          </template>
          <template v-else>
            <button class="btn btn-sm rounded-pill d-flex align-items-center ml-2" @click="buttonClicked(button.id)">
              <div :class="['d-flex', 'align-items-center', 'justify-content-center', { 'pr-1': button.name }]">
                <span class="material-icons">{{ button.icon }}</span>
              </div>
              <div>
                {{ button.name }}
              </div>
            </button>
          </template>
        </template>
        <template v-else>
          <button class="btn btn-sm rounded-pill d-flex align-items-center ml-2" @click="buttonClicked(button.id)">
            <div :class="['d-flex', 'align-items-center', 'justify-content-center', { 'pr-1': button.name }]">
              <span class="material-icons">{{ button.icon }}</span>
            </div>
            <div>
              {{ button.name }}
            </div>
          </button>
        </template>
      </div>
      <div class="continue d-flex align-items-center justify-content-end">
        <span class="material-icons">
          chevron_right
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    buttonClicked: {
      type: Function,
      required: true,
    },
  },
});
</script>

<style>
.button-container {
  width: fit-content;
  top: 20px;
  right: 2%;
  z-index: 600;
}

button {
  white-space: nowrap;
}

.btn-success {
  color: #fff;
}

@media (max-width: 900px) {
  .button-container {
    width: 100%;
    left: 0;
    right: unset;
  }

  .backButton {
    left: 14%;
  }
}

.mapboxgl-ctrl-top-right {
  top: 60px !important;
}

@media (max-width: 420px) {
  .button-container{
    top: 0;
    padding: 10px 0px;
    background: #0000006b;
  }
}
</style>
