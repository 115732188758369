/**
 * Returns a weather icon corresponding to the given weather code.
 *
 * @param {number} code - The weather code (WMO code) from the API.
 * @returns {string} An emoji representing the weather, or a default icon if not found.
 */

/**
 * Returns a weather icon for the given WMO weather code,
 * customized for day or night.
 *
 * @param {number} code - The WMO weather code from the API.
 * @param {boolean} isDay - true if it's daytime, false if it's nighttime.
 * @returns {string} An emoji representing the weather.
 */
export default function getWeatherIcon(weather) {
  const code = weather.daily.weather_code[0]
  const isDay = weather.current.is_day
  // Codes that differ by day/night:
  const dayIcons = {
    0: '☀️',   // Clear sky (day)
    1: '🌤️',   // Mainly clear
    2: '⛅',    // Partly cloudy
    3: '☁️',    // Overcast
  };

  // For night, you may want a moon or stars.
  // There's no "partly cloudy moon" emoji in standard sets, so we approximate.
  const nightIcons = {
    0: '🌙',    // Clear sky (night)
    1: '🌙☁️',  // Mainly clear with a moon
    2: '⛅🌙',   // Partly cloudy at night (approx)
    3: '☁️',    // Overcast (same as day, or pick another)
  };

  // Codes that generally look the same for day or night:
  const universalIcons = {
    45: '🌫️',  // Fog
    48: '🌫️',  // Depositing rime fog
    51: '🌦️',  // Drizzle: light
    53: '🌦️',  // Drizzle: moderate
    55: '🌦️',  // Drizzle: dense
    56: '🌧️',  // Freezing drizzle: light
    57: '🌧️',  // Freezing drizzle: dense
    61: '🌧️',  // Rain: slight
    63: '🌧️',  // Rain: moderate
    65: '🌧️',  // Rain: heavy
    66: '🌧️',  // Freezing rain: light
    67: '🌧️',  // Freezing rain: heavy
    71: '❄️',   // Snow fall: slight
    73: '❄️',   // Snow fall: moderate
    75: '❄️',   // Snow fall: heavy
    77: '❄️',   // Snow grains
    80: '🌦️',  // Rain showers: slight
    81: '🌦️',  // Rain showers: moderate
    82: '🌦️',  // Rain showers: violent
    85: '❄️',   // Snow showers: slight
    86: '❄️',   // Snow showers: heavy
    95: '⛈️',   // Thunderstorm: slight or moderate
    96: '⛈️',   // Thunderstorm with slight hail
    99: '⛈️',   // Thunderstorm with heavy hail
  };

  // Decide which icon sets to merge:
  // 1) If the code has a universal icon, return that.
  // 2) Else if it's a day code, check dayIcons. Otherwise nightIcons.
  if (universalIcons[code]) {
    return universalIcons[code];
  } else if (isDay) {
    return dayIcons[code] || '❓';
  } else {
    return nightIcons[code] || '❓';
  }
}

function checkDaytime(locationData) {
  // current local time (e.g. "2025-02-07T19:15")
  const currentTime = new Date(locationData.current.time);

  // For a simple check, use today's sunrise/sunset from daily.* index 0
  // (assuming [0] is "today")
  const sunrise = new Date(locationData.daily.sunrise[0]);
  const sunset = new Date(locationData.daily.sunset[0]);

  return currentTime >= sunrise && currentTime < sunset;
}

