<template>
  <!-- Card transitions in/out from bottom of screen -->
  <transition name="trail-card-slide">
    <div class="trail-data-card" :class="{ reduce: isReduced }" v-if="hasData">

      <!-- Weather + Graph Container -->
      <TrailDataCardWeather
        v-if="showWeather"
        :showWeather="showWeather"
        :weather="weather?.start"
        :shortAddress="short_address"
        @hide-weather="hideWeather"
      />

      <!-- Top Controls (reduce/expand, open-in-new link) -->
      <span class="hide-show material-icons" @click="toggleReduce">
        {{ isReduced ? 'add' : 'remove' }}
      </span>

      <!-- Left Panel: Trail Data -->
      <div class="left-panel align-content-center">
        <div class="d-flex align-self-start thumbnail-container">
          <img class="object-fit-cover" :src="mainImageUrl" alt="" />
        </div>

        <div class="px-2 w-100 d-flex flex-column justify-content-center p-2">
          <p class="d-flex flex-row">

            <!-- Weather badge -->
            <span
              v-if="weather && weather.start"
              @click="toggleWeather()"
              class="badge badge-dark d-flex align-items-center mr-1 px-2 py-1"
              style="cursor: pointer;"
              ref="weatherTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-html="true"
              :title="weatherTooltipContent"
            >
              <small class="pr-1">{{ currentWeatherIcon }}</small>
              {{ weather.start.daily.temperature_2m_max[0] }}°C
              <span class="material-icons ml-1" style="font-size: 1.2em;">add</span>
            </span>

            <!-- Rating -->
            <span
              v-if="averageRating"
              class="badge badge-dark d-flex align-items-center mr-1 px-2 py-1"
            >
              <small>
                <span class="material-icons mr-1">star</span>
              </small>
              {{ averageRating }}
            </span>

            <!-- Dropdown menu: export GPX, share, etc. -->
            <span class="dropdown">
              <span
                class="badge badge-dark d-flex align-items-center mr-1 px-2 py-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  ref="optionsButtonTooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-html="true"
                  title="See options to modify this trail."
                >
                  <small>
                    <span class="material-icons" style="font-size: 17px;">more_vert</span>
                  </small>
                </span>
              </span>
              <ul class="dropdown-menu">
                <li class="dropdown-item" @click="onGpxClick">
                  <small>EXPORT GPX</small>
                </li>
                <li class="dropdown-item">
                  <a :href="editUrl"><small>EDIT ROUTE</small></a>
                </li>
                <li v-if="isDetailsEditable" class="dropdown-item" @click="editDetails">
                  <small>EDIT DETAILS</small>
                </li>
                <li class="dropdown-item" @click="onShareClick">
                  <small>SHARE TRAIL</small>
                </li>
                <li class="dropdown-item" @click="preview">
                  <small>3D PREVIEW</small>
                </li>
                <li v-if="isCropable" class="dropdown-item" @click="crop">
                  <small>CROP RECORDING</small>
                </li>
                <li v-if="isDeletable" class="dropdown-item" @click="onDeleteClick">
                  <small>DELETE TRAIL</small>
                </li>
              </ul>
            </span>

            <!-- "Open in new" link -->
            <a :href="shareableUrl" class="badge badge-dark d-flex align-items-center mr-1 px-2 py-1">
              <small><span class="material-icons" style="font-size: 17px;">open_in_new</span></small>
            </a>
          </p>

          <a :href="shareableUrl"><strong>{{ name }}</strong></a>
          <p v-if="short_address" class="mb-1">{{ short_address }}</p>
          <p class="text-muted"><small>{{ difficulty }}</small></p>
          <p>
            <small>
              {{ formatDistance(distanceMeters) }}
              <span class="mx-1">&#183;</span>
              {{ formatElevation(elevationGainMeters) }}
              <span class="mx-1">&#183;</span>
              ~{{ duration() }}
            </small>
          </p>
        </div>
      </div>

      <!-- Right Panel: Elevation Profile -->
      <div class="right-panel p-2 position-relative">
        <div class="elevation-profile-placeholder">
          <div class="chart-container">
            <canvas ref="chartCanvas"></canvas>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import Chart from 'chart.js';
import mapboxgl from 'mapbox-gl';
import { distance as turfDistance } from '@turf/turf';
import { MeasurementPrefUtility } from '../utils/helpers/MeasurementPref';
import { HikeTimeUtility, PrettyTime } from '../utils/helpers/HikeTime';
import getWeatherIcon from '../utils/getWeatherIcon';
import getWeatherDescription from "../utils/getWeatherDescription";
import TrailDataCardWeather from './TrailDataCardWeather.vue';
import 'chartjs-plugin-annotation'; // important for annotation lines
import togpx from "togpx";
import graph_client from "../utils/graphql/graph_client";
import { DestroyPtv2PlannedTrail } from "../utils/graphql/planned-trail-graph";
import { RevenueCatWrapper } from "../utils/RevenueCatWrapper";
import { HiikerMapStyle } from "../utils/models/MapStyles";
import { DestroyRecording } from "../utils/graphql/recorded-trail-graph";
import { Tooltip } from 'bootstrap';

// Keep a global reference to the marker
let elevationMarker: mapboxgl.Marker | null = null;

export default defineComponent({
  name: 'TrailDataCard',
  props: {
    reduced: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TrailDataCardWeather,
  },
  data() {
    return {
      // UI
      isReduced: this.reduced,
      isLoading: false,
      isDetailsEditable: false,
      actionMenuOpen: false,

      // Trail info
      id: '',
      type: '',
      name: '',
      short_address: '',
      difficulty: '',
      averageRating: '',
      elevationGainMeters: 0,
      distanceMeters: 0,
      durationSeconds: 0,
      isDeletable: false,
      isCropable: false,

      // Geometry & images
      featureCollection: null as any,
      mainImageUrl: '',
      createdAt: '',
      shareableUrl: '',
      startPoint: null as number[] | null,
      endPoint: null as number[] | null,

      // Chart
      chartInstance: null as Chart | null,

      // Weather
      weather: null as any,
      showWeather: false,
      weatherTooltip: null,
      optionsTooltip: null,
      editUrl: null
    };
  },
  watch: {
    reduced(newVal) {
      this.isReduced = newVal;
    },
  },
  computed: {
    hasData() {
      return !!this.featureCollection && !!this.featureCollection.features?.length;
    },
    currentWeatherIcon() {
      if (!this.weather?.start?.daily) return '☀️';
      return getWeatherIcon(this.weather.start);
    },
    currentWeatherDescription() {
      if (!this.weather?.start?.daily) return '';
      return getWeatherDescription(this.weather.start.daily.weather_code[0]);
    },
    weatherTooltipContent() {
      return `<span class="text-center d-flex flex-column"><strong>${this.currentWeatherDescription}</strong><small style='opacity: 0.5;'>click for more</small></span>`;
    },

    /**
     * Build the data for our line chart:
     * - xLabels = distance in user’s bigUnit (km or miles)
     * - dataPoints = elevation in user’s smallUnit
     * - lat/lon arrays so we can place the map marker
     */
    simplifiedChartData() {
      if (!this.featureCollection?.features) return null;

      const coords = this.getFeatureCollectionLineString();
      if (!coords || !coords.length) return null;

      // Reduce to at most 50 points
      const maxPoints = 50;
      const divider = Math.ceil(coords.length / maxPoints);

      const measurementUtils = MeasurementPrefUtility.preference();
      const xLabels: string[] = [];
      const dataPoints: number[] = [];

      const latArray: number[] = [];
      const lonArray: number[] = [];

      let distanceAccum = 0;
      for (let i = 0; i < coords.length; i += divider) {
        if (i === 0) {
          distanceAccum = 0;
        } else {
          distanceAccum += turfDistance(coords[i - divider], coords[i], {
            units: 'meters',
          });
        }
        const [lon, lat, elevation] = coords[i];
        const ele = elevation || 0;

        xLabels.push(measurementUtils.bigUnitFormatter(distanceAccum / 1000, false));
        dataPoints.push(measurementUtils.smallUnitValue(ele));
        latArray.push(lat);
        lonArray.push(lon);
      }

      return {
        labels: xLabels,
        datasets: [
          {
            label: 'Elevation',
            data: dataPoints,
            borderColor: '#ffffff',
            borderWidth: 2,
            pointRadius: 0,
          },
        ],
        lat: latArray,
        lon: lonArray,
      };
    },
  },
  methods: {
    /** Toggle the "reduced" (collapsed) card mode */
    toggleReduce() {
      this.isReduced = !this.isReduced;
      this.$emit('update:reduced', this.isReduced);
      this.$emit('toggle-reduce', this.isReduced); // Add this line
    },
    hideWeather() {
      this.showWeather = false;
    },
    /** Insert or dispose of the Bootstrap tooltip for weather */
    addTooltip() {
      if (this.weatherTooltip) {
        this.weatherTooltip.dispose();
      }
      if (this.$refs.weatherTooltip) {
        this.weatherTooltip = new Tooltip(this.$refs.weatherTooltip, {
          trigger: "hover",
          placement: "top",
          customClass: 'weather-tooltip'
        });
      }
      if (this.optionsTooltip) {
        this.optionsTooltip.dispose();
      }
      if (this.$refs.optionsButtonTooltip) {
        this.optionsTooltip = new Tooltip(this.$refs.optionsButtonTooltip, {
          trigger: "hover",
          placement: "top",
          customClass: 'weather-tooltip'
        });
      }
    },
    toggleWeather() {
      this.showWeather = !this.showWeather;
    },

    // GPX exporting
    onGpxClick() {
      const gpx = togpx({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: this.getFeatureCollectionLineString(),
        }
      }, {
        creator: 'Hiiker'
      });

      // Create a Blob and a temporary anchor element
      const blob = new Blob([gpx], { type: 'application/gpx+xml' });
      const url = URL.createObjectURL(blob);

      // Create a download link
      const a = document.createElement('a');
      a.href = url;
      a.download = 'track.gpx'; // File name
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    onShareClick() {
      // copy shareableUrl to clipboard
      navigator.clipboard.writeText(this.shareableUrl).then(() => {
        alert('Trail share URL copied to clipboard');
      });
    },

    preview() {
      if (this.type === 'Recording') {
        window.showMapModalForRecordedTrail(this.id)
      } else if (this.type === 'Ptv2::PlannedTrail') {
        window.showMapModalForPlannedTrail(this.id)
      } else if (this.type === 'Trail') {
        window.showMapModalForTrail(this.id)
      }
    },

    crop() {
      window.location.href = `${this.shareableUrl}/crop`
    },

    editDetails() {
      window.showEditRecordingModal(this.id);
    },

    onDeleteClick() {
      const confirmClear = confirm('Are you sure you want to delete this route?');
      if (confirmClear) {
        if (this.type === 'Recording') {
          // Delete a Recording
          graph_client.request(DestroyRecording, { input: { id: this.id } })
            .then(async () => {
              alert('Recording deleted successfully. Page will now reload.');
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
              alert('Failed to delete trail. Please try again later.');
            });
        } else if (this.type === 'Ptv2::PlannedTrail') {
          graph_client.request(DestroyPtv2PlannedTrail, { input: { id: this.id } })
            .then(async () => {
              alert('Planned Trail deleted successfully. Page will now reload.');
              window.location.reload();
            })
            .catch((err) => {
              console.log(err);
              alert('Failed to delete trail. Please try again later.');
            });
        } else if (this.type === 'Trail') {
          // call deleteTrail(this.id) or something similar
          console.log('Deleting Trail:', this.id);
        }
      }
    },

    /** Return the actual array of coords from the first LineString in the FeatureCollection */
    getFeatureCollectionLineString() {
      if (!this.featureCollection?.features) return null;
      const coordinates = [];
      for (const feature of this.featureCollection.features) {
        if (feature.geometry.type === 'LineString') {
          for (const coord of feature.geometry.coordinates) {
            coordinates.push(coord);
          }
        }
      }
      return coordinates;
    },

    /** Create the custom Marker for the "hover" pin on the map */
    createElevationMarker() {
      const marker = new mapboxgl.Marker();
      const el = document.createElement('div');
      el.style.display = 'block';
      el.style.border = '3px solid #fff';
      el.style.cursor = 'pointer';
      el.style.padding = '0px';
      el.style.marginTop = '13px';
      el.style.backgroundColor = '#bbb';
      el.style.borderRadius = '100%';
      el.style.width = '20px';
      el.style.height = '20px';
      el.style.backgroundSize = 'cover';
      el.style.backgroundRepeat = 'no-repeat';
      el.style.backgroundPosition = 'center center';
      // @ts-ignore
      marker._element = el;
      return marker;
    },

    formatDistance(distance: number) {
      const measurementPref = MeasurementPrefUtility.preference();
      return measurementPref.smallUnitFormatter(distance, true);
    },
    formatElevation(elevation: number) {
      const measurementPref = MeasurementPrefUtility.preference();
      return measurementPref.smallUnitFormatter(elevation, false);
    },
    duration() {
      const prettyTime = new PrettyTime(this.durationSeconds).prettyTime();
      return prettyTime;
    },

    /** Look up the start & end coordinates; used for fetching weather, etc. */
    setStartPointAndEndPointFromFeatureCollection() {
      if (!this.featureCollection?.features) return;
      for (const feature of this.featureCollection.features) {
        if (feature.geometry.type === 'LineString') {
          this.startPoint = feature.geometry.coordinates[0];
          this.endPoint =
            feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
          break;
        }
      }
    },

    /** Return the coords with the highest altitude from the line. */
    highestPointCoords() {
      const coords = this.getFeatureCollectionLineString();
      if (!coords || !coords.length) return null;
      const maxAltitude = coords.reduce((acc, coord) => {
        if (coord[2] > acc[2]) return coord;
        return acc;
      }, coords[0]);
      return maxAltitude;
    },

    /** Fetch weather for start, end, and highest coords. */
    getWeather() {
      if (!this.startPoint || !this.endPoint) {
        this.weather = null;
        return;
      }
      const highest = this.highestPointCoords();
      if (!highest) {
        this.weather = null;
        return;
      }

      const [startLng, startLat] = this.startPoint;
      const [endLng, endLat] = this.endPoint;
      const [highLng, highLat] = highest;

      // Build lat/lng arrays
      const latArray = [startLat, endLat, highLat].join(',');
      const lngArray = [startLng, endLng, highLng].join(',');

      const params = new URLSearchParams({
        latitude: latArray,
        longitude: lngArray,
        daily: 'temperature_2m_min,weather_code,sunrise,sunset,temperature_2m_max,temperature_2m_min,precipitation_sum',
        timezone: 'auto',
        forecast_days: '1',
        current: 'is_day',
      });

      fetch(`https://api.open-meteo.com/v1/forecast?${params}`)
        .then((res) => res.json())
        .then((locationsArray) => {
          if (!Array.isArray(locationsArray) || locationsArray.length < 3) {
            console.warn('Expected array of 3 location results:', locationsArray);
            this.weather = null;
            return;
          }
          this.weather = {
            start: this.parseWeatherObject(locationsArray[0]),
            end:   this.parseWeatherObject(locationsArray[1]),
            high:  this.parseWeatherObject(locationsArray[2]),
          };
          this.$nextTick(() => {
            this.addTooltip();
          });
        })
        .catch((err) => {
          console.error('Fetch Error:', err);
          this.weather = null;
        });
    },

    /** Simplified parse of each weather object */
    parseWeatherObject(locationData) {
      if (!locationData) return null;
      const { latitude, longitude, elevation, current, hourly, daily } = locationData;
      return {
        lat: latitude,
        lng: longitude,
        elevation,
        current,
        hourly,
        daily,
      };
    },

    /**
     * Draw the Chart.js line chart with:
     * - Our custom vertical line plugin
     * - The "annotation" lines for start/end/high weather
     * - The hover.onHover callback that moves the Mapbox marker
     */
    drawChart() {
      if (!this.simplifiedChartData) return;

      const canvas = this.$refs.chartCanvas as HTMLCanvasElement;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Destroy old instance if re-drawing
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      // Identify start, end, highest indices for annotation
      const dataArray = this.simplifiedChartData.datasets[0].data;
      const startX = 0;
      const endX = this.simplifiedChartData.labels.length - 1;
      const highX = dataArray.indexOf(Math.max(...dataArray));

      // Custom plugin for the vertical hover line
      const verticalLinePlugin = {
        afterDatasetsDraw: (chart) => {
          if (chart.tooltip?._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            const activePoint = chart.tooltip._active[0];
            const x = activePoint.tooltipPosition().x;
            const topY = chart.chartArea.top;
            const bottomY = chart.chartArea.bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#ffffff';
            ctx.stroke();
            ctx.restore();
          }
        },
      };

      // Create the chart
      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: this.simplifiedChartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          hover: {
            mode: 'index',
            intersect: false,
            // **Marker on hover**:
            onHover: (event, activeElements) => {
              // If mouse leaves chart
              if (event.type === 'mouseout') {
                if (elevationMarker) {
                  elevationMarker.remove();
                  elevationMarker = null;
                }
                return;
              }
              // If hovering on a point
              if (activeElements && activeElements.length) {
                // The hovered data index
                const hoveredIndex = activeElements[0]._index;
                // Our lat/lon arrays are stored on the chart data:
                const chartData: any = activeElements[0]._chart.data;
                const lat = chartData.lat[hoveredIndex];
                const lon = chartData.lon[hoveredIndex];

                // Create the marker if it doesn't exist
                if (!elevationMarker) {
                  elevationMarker = this.createElevationMarker();
                }

                // Move marker to the hovered lat/lon
                if ((window as any).map) {
                  elevationMarker.setLngLat([lon, lat]).addTo((window as any).map);
                }
              }
            },
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: { display: false },
          scales: {
            xAxes: [
              {
                id: 'x-axis-0', // The default
                type: 'category',
                position: 'bottom',
                ticks: {
                  fontColor: '#ffffff',
                },
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                id: 'y-axis-0',
                ticks: {
                  fontColor: '#ffffff',
                },
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
        plugins: [verticalLinePlugin],
      });
    },
  },
  created() {
    // Expose window.* methods for loading data into this card
    window.trailCardDataRecording = (recording) => {
      this.id = recording.id;
      this.name = recording.title;
      this.short_address = recording.startAddress?.city;
      this.featureCollection = JSON.parse(recording.lineString);
      this.elevationGainMeters = recording.elevationGainMeters;
      this.durationSeconds = recording.elapsedSeconds;
      this.mainImageUrl = recording.thumbnailUrl;
      this.createdAt = recording.createdAt;
      this.shareableUrl = recording.shareableUrl;
      this.distanceMeters = recording.distanceMeters;
      this.type = 'Recording';
      this.setStartPointAndEndPointFromFeatureCollection();
      this.getWeather();
      this.editUrl = `/trail-planner?trail_type=Recording&trail_id=${recording.id}`;
      this.isDeletable = true;
      this.isCropable = true;
      this.isDetailsEditable = true;
    };

    window.trailCardDataPtv2PlannedTrail = (plannedTrail) => {
      this.id = plannedTrail.id;
      this.name = plannedTrail.title;
      this.type = 'Ptv2::PlannedTrail';
      this.featureCollection = plannedTrail.geojsonFeatureCollection;
      this.elevationGainMeters = plannedTrail.elevationGainMeters;
      this.durationSeconds = plannedTrail.calculatedDurationMinutes * 60;
      this.mainImageUrl = plannedTrail.thumbnailUrl;
      this.createdAt = plannedTrail.createdAt;
      this.shareableUrl = plannedTrail.shareableUrl;
      this.distanceMeters = plannedTrail.distanceMeters;
      this.setStartPointAndEndPointFromFeatureCollection();
      this.getWeather();
      this.editUrl = this.shareableUrl + '/edit';
      this.isDeletable = true;
      this.isCropable = false;
      this.isDetailsEditable = false;
    };

    window.trailCardClearData = () => {
      this.featureCollection = null;
      this.weather = null;
      this.isCropable = false;
      this.isDeletable = false;
      this.isDetailsEditable = false;

      this.$emit('card-active', false); // card becomes inactive
    };

    window.trailCardDataTrail = (trail) => {
      this.elevationGainMeters = trail.totalElevation;
      this.id = trail.id;
      this.name = trail.name;
      this.difficulty = trail.difficulty;
      this.averageRating = trail.averageRating;
      this.type = 'Trail';
      this.featureCollection = JSON.parse(trail.lineString.geoData);
      this.durationSeconds = trail.durationMinutes * 60;

      if (trail.photos && trail.photos.length > 0) {
        this.mainImageUrl = trail.photos[0].url;
      } else {
        this.mainImageUrl = trail.mobileStaticMapImage;
      }
      this.createdAt = trail.createdAt;
      const origin = window.location.origin;
      this.shareableUrl = `${origin}/trails/${trail.countrySlug}/${trail.regionSlug}/${trail.slug}`;
      this.distanceMeters = trail.trailDistance;

      this.setStartPointAndEndPointFromFeatureCollection();
      this.getWeather();
      this.editUrl = `/trail-planner?trail_type=Trail&trail_id=${trail.id}`;
      this.isDeletable = false;
      this.isCropable = false;
      this.isDetailsEditable = false;

      // ADD THIS LINE:
      this.$emit('card-active', true); // card becomes active
    };
  },
  mounted() {
    // Draw once mounted
    this.drawChart();
  },
  watch: {
    featureCollection: {
      immediate: true,
      handler() {
        nextTick(() => {
          this.drawChart();
        });
        this.setStartPointAndEndPointFromFeatureCollection();
      },
    },
  },
});
</script>

<style scoped>
/* Main trail card container */
.trail-data-card {
  position: absolute;
  display: flex;
  flex-direction: row;
  max-width: 960px;
  width: 80%;
  margin: 1em auto;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 10px;
  background: rgba(23, 24, 29, 0.85);
  border: 2px solid var(--gray);
}

.trail-data-card.reduce {
  max-width: 510px;
}

/* Top Controls */
.hide-show {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  top: 12px;
  right: 10px;
}

.dropdown-menu{
  overflow: hidden;
}

.dropdown-item{
  cursor: pointer;
}

.expand-trail {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  top: 12px;
  right: 52%;
}

.reduce .expand-trail {
  right: 50px;
}

.reduce .hide-show {
  right: 20px;
}

/* Left Panel */
.left-panel {
  flex: 0 0 50%;
  border-right: 1px solid rgba(172, 176, 191, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trail-data-card.reduce .left-panel {
  flex: 1;
}

/* Right Panel: Elevation Profile */
.right-panel {
  flex: 0 0 45%;
  padding: 10px;
  position: relative;
}

.trail-data-card.reduce .right-panel {
  display: none;
}

/* Thumbnail container */
.thumbnail-container {
  width: 100px;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* Elevation chart container */
.elevation-profile-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.chart-container {
  width: 100%;
  max-height: 100px;
  position: relative;
}

.chart-container canvas {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

/* Card transition */
.trail-card-slide-enter-active,
.trail-card-slide-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.trail-card-slide-enter {
  transform: translateY(100%);
  opacity: 0;
}

.trail-card-slide-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

/* On narrower screens, hide the right panel entirely */
@media screen and (max-width: 822px) {
  body, html {
    font-size: 12px;
  }
  .trail-data-card {
    width: 73%;
    left: 20px;
    right: unset;
    max-width: 420px;
  }

  .left-panel {
    flex: 0 0 100%;
    border-right: none;
  }
  .right-panel {
    display: none;
  }
  .expand-trail,
  .hide-show {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .trail-data-card {
    width: 90%;
  }
}
</style>
