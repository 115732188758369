<template>
  <div class="map-buttons-container">

    <button class="map-btn d-block d-lg-none" v-if="showList" @click="showListAction">
      <p class="p-1 m-0 d-flex align-items-center justify-content-center">
        <span class="material-icons" id="list_icon">format_list_bulleted</span>
      </p>
    </button>

    <button class="map-btn" @click="orientationButton">
      <p class="p-1 m-0 d-flex align-items-center justify-content-center">
        <span>
          <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0L11.1962 9H0.803848L6 0Z" fill="white"/>
            <path opacity="0.4" d="M6 23L0.803849 14L11.1962 14L6 23Z" fill="white"/>
          </svg>
        </span>
      </p>
    </button>

    <button class="map-btn" @click="locationButton">
      <p class="p-1 m-0 d-flex align-items-center justify-content-center">
        <span class="material-icons" id="location_icon">my_location</span>
      </p>
    </button>

    <button class="map-btn zoom-btn">
      <p class="p-1 m-0 d-flex align-items-center justify-content-center" @click="zoomIn"><span class="material-icons btn-plus">add</span></p>
      <p class="p-1 m-0 d-flex align-items-center justify-content-center" @click="zoomOut"><span class="material-icons btn-minus">remove</span></p>
    </button>

  </div>
</template>

<script>

export default {
  props: {
    showList: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.geolocateLocated = () => {
      const icon = document.getElementById('location_icon');
      icon.classList.remove('spin');
    };
    window.geolocateError = () => {
      const icon = document.getElementById('location_icon');
      icon.classList.remove('spin');
    };
  },
  methods: {
    zoomIn() {
      window.map.zoomIn();
    },
    zoomOut() {
      window.map.zoomOut();
    },
    locationButton() {
      // start animation
      const icon = document.getElementById('location_icon');
      icon.classList.add('spin');
      window.geoLocate.permissionAndTrigger();
      if (window.geoLocate._lastKnownPosition) {
        icon.classList.remove('spin');
      }
    },
    orientationButton() {
      window.map.flyTo({
        pitch: 0,
        bearing: 0,
      });
    },
    showListAction() {
      this.$emit('showListAction');
    }
  },
};
</script>

<style scoped>

.map-buttons-container {
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.map-btn {
  padding: 0 !important;
  margin-bottom: 1em;
  text-align: center;
  width: 36px;
  height: 36px;
  position: relative;
  border: none;
  background-color: rgba(17, 17, 17, 0.8);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  color: #fff;
}

.zoom-btn{
  height: 80px
}

.zoom-btn i.btn-plus {
  margin-top: -4px;
}

.zoom-btn i.btn-minus {
  margin-top: 4px;
}

/* Define the spin animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Class to apply the spin animation to the icon */
.spin {
  animation: spin 1s linear infinite;
}

</style>
